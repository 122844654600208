/* eslint-disable react/no-array-index-key */
import {
  Box,
  IndexTable,
  SkeletonBodyText,
  SkeletonThumbnail,
} from '@shopify/polaris-internal';
import React from 'react';

import styles from './SkeletonTable.module.css';

interface SkeletonTableProps {
  numColumns: number;
  numRows?: number;
  selectable?: boolean;
  withThumbnail?: boolean;
}

export function SkeletonTable({
  numColumns,
  numRows = 2,
  selectable = true,
  withThumbnail = false,
}: SkeletonTableProps) {
  const cols = Array.from({length: numColumns}, (_, index) => index);
  const rows = Array.from({length: numRows}, (_, index) => index);

  const headings = cols.map((_, index) => ({
    title: (
      <Box paddingBlockStart="150" paddingBlockEnd="150">
        <SkeletonBodyText lines={1} />
      </Box>
    ),
    id: index.toString(),
    hidden: withThumbnail && index === 0,
  })) as [{title: React.ReactNode; id: string; hidden: boolean}];

  return (
    <div
      ref={(item) => {
        // why am I doing this in a ref instead of directly in jsx, you might ask
        // TS Types doesn't know about this attribute yet: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/60822/files
        // React doesn't yet support this being a boolean instead of a string: https://github.com/facebook/react/pull/24730
        // This seemed the safest way to do it for now
        item?.setAttribute('inert', '');
      }}
    >
      <IndexTable
        itemCount={numRows}
        headings={headings}
        selectable={selectable}
      >
        {rows.map((_, rowIndex) => (
          <IndexTable.Row
            key={rowIndex}
            id={rowIndex.toString()}
            position={rowIndex}
          >
            {cols.map((_, columnIndex) =>
              withThumbnail && columnIndex === 0 ? (
                <IndexTable.Cell key={columnIndex} className={styles.ImageCell}>
                  <SkeletonThumbnail size="small" />
                </IndexTable.Cell>
              ) : (
                <IndexTable.Cell key={columnIndex}>
                  <Box paddingBlockStart="200" paddingBlockEnd="200">
                    <SkeletonBodyText lines={1} />
                  </Box>
                </IndexTable.Cell>
              ),
            )}
          </IndexTable.Row>
        ))}
      </IndexTable>
    </div>
  );
}
